@font-face {
    font-family: 'bellering';
    src: url('./assets/fonts/bellering/Bellering.otf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'fordier';
    src: url('/assets/fonts/fordier/Fordier.otf') format('woff2');
  }

  @font-face {
    font-family: 'quining';
    src: url('/assets/fonts/quining/Quning.otf') format('woff2');
  }

  @font-face {
    font-family: 'thingle';
    src: url('/assets/fonts/thingle/Thingle.ttf') format('woff2');
  }

  @font-face {
    font-family: 'margeret';
    src: url('/assets/fonts/Margaret/Margaret.otf') format('woff2');
  }
  
  @font-face {
    font-family: 'rudge';
    src: url('/assets/fonts/rudge/Rudge.otf') format('woff2');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'courgette';
    src: url('/assets/fonts/Courgette/Courgette-Regular.ttf') format('woff2');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'italianno';
    src: url('/assets/fonts/Italianno/Italianno-Regular.ttf') format('woff2');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'grandhotel';
    src: url('/assets/fonts/Grand_Hotel/GrandHotel-Regular.ttf') format('woff2');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'marck';
    src: url('/assets/fonts/Marck_Script/MarckScript-Regular.ttf') format('woff2');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'grandiflora';
    src: url('/assets/fonts/Grandiflora_One/GrandifloraOne-Regular.ttf') format('woff2');
    font-weight: 200;
    font-style: normal;
  }

